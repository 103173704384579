<script>
import VerticalAnnonce from '../../shared-component/vertical-annonce/vertical-annonce.vue'

import {ToastMixin} from '../../../mixins/toast-mixin.js'
import {HandleFavoriteMixin} from '../../../mixins/handle-favorite-mixin.js'

export default {
    name: "TabComponent",
    components: {
        VerticalAnnonce
    },
    mixins: [ToastMixin, HandleFavoriteMixin],
    props:['tab1Name','tab2Name'],
    data() {
        return {
            active: this.tab1Name,
        }
    },
    created(){
        this.makeActive('tab1');
    },
    methods: {
		makeActive(item){
            // When a model is changed, the view will be automatically updated.
            this.active = item;
            console.info("active == " + item);
		}
	} 
};
</script>

<style lang="scss">
    @import "tab-component.scss";
</style>
<template src="./tab-component.html"></template>