<script>

import TabComponent from '../../shared-component/tab-component/tab-component.vue'
import LoginComponent from '../../shared-component/login-component/login-component.vue'
import RegisterComponent from '../../shared-component/register-component/register-component.vue'

export default {
    name: "LoginRegisterComponent",
    components: {
       TabComponent,LoginComponent,RegisterComponent
    },
    props:['onSucceed'],
    created(){
      
    },
    methods: {
       onLoginOrRegisterSucceed(){
            this.onSucceed();
       }
    }    
};
</script>

<style lang="scss">
    @import "login-register-component.scss";
</style>

<template src="./login-register-component.html"></template>