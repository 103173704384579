<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

import InputValidatorsComponent from '../../shared-component/input-validators-component/input-validators-component.vue'
import TabComponent from '../../shared-component/tab-component/tab-component.vue'
import ModalEmailComponent from '../../shared-component/modal-email-component/modal-email-component.vue'
import ModalInfoComponent from '../../shared-component/modal-info-component/modal-info-component.vue'
import {FormValidatorsMixin} from '../../../mixins/form-validators-mixin.js'


import AuthService from '../../../services/AuthService';


export default {
    name: "LoginComponent",
    components: {
        InputValidatorsComponent,TabComponent,ModalEmailComponent,ModalInfoComponent
    },
    props:['onSucceed'],
    mixins: [FormValidatorsMixin],
    data() {
        return {
            motDePasseOublieActif:false,
             email: '',
             emailForReinit: '',
            emailForReinitState:null,
            // Form value
            form: {
                userName: '',
                userPassword: ''
            },
            // Form input field, valid or not
            formValid: {
                userName: false,
                userPassword: false
            },
             isConnexionActive: false,
             errorMessage:false,
             connecting:false,
             infoModalText:"",
        }
    },

    created(){
      
    },
    methods: {
        closeModeDePasseOublie(){
            this.motDePasseOublieActif = false;
        },
        connexionStandard(e) {
            if (this.formValid.userName && this.formValid.userPassword){
                this.connecting = true;
                console.info('connexion standard (login/pass en cours)... ');
                console.info('login = ' + this.form.userName);
                console.info('password = ' + this.form.userPassword);
                e.preventDefault();
                AuthService.signInUser(this.form.userName,this.form.userPassword).then(
                () => {
                    this.connecting = false;
                    this.errorMessage = false;
                    console.info("Connexion reussie ! " + firebase.auth().currentUser)
                    //router.push('MonCompte');
                    this.onSucceed();
                },
                (error) => {
                     this.connecting = false;
                    console.error(error);
                    this.errorMessage = true;
                }
                );
            } else {
                console.info("login/pass invalides !");
                console.info("login validity : " + this.formValid.userName);
                console.info("pass validity : " + this.formValid.userPassword);
            }
            
        },

         afterSetValidatedState(validated, inputValue, fieldName){
              console.info("afterSetValidatedState("+validated + "," + inputValue + "," + fieldName + ")");
             this.isConnexionActive = this.formValid.userName && this.formValid.userPassword;
         },

         googleLogin(){
              console.info('googleLogin');
               this.connecting = true;
              AuthService.signInGoogleUser().then(
                () => {
                    this.connecting = false;
                    this.errorMessage = false;
                    console.info("Connexion Google reussie ! " + firebase.auth().currentUser)
                    //router.push('MonCompte');
                    this.onSucceed();
                },
                (error) => {
                     this.connecting = false;
                    console.error(error);
                    this.errorMessage = true;
                }
                );
         },
         facebookLogin(){
              console.info('facebookLogin');
              this.connecting = true;
              AuthService.signInFacebookUser().then(
                () => {
                    this.connecting = false;
                    this.errorMessage = false;
                    console.info("Connexion Facebook reussie ! " + firebase.auth().currentUser)
                    this.onSucceed();
                },
                (error) => {
                     this.connecting = false;
                    console.error(error);
                    this.errorMessage = true;
                }
                );
         },

         forgottenPassword(){
            console.info("click sur Mot de passe oublié.");
            this.motDePasseOublieActif = true;
         },

         resetPasswordOk(email){
            console.info("resetPasswordOk : " + email);
            AuthService.sendResetPasswordEmail(email).then(()=>{
                this.infoModalText = "Un email de ré-initialisation de mot de passe vient de vous être envoyé.";
                 this.$refs.modalInfo.showModal();
            },(error)=>{
                console.info("error : " + error);
                 this.infoModalText = "Aucun compte n'est associé a cet email !";
                 this.$refs.modalInfo.showModal();
            });
         },
         resetPasswordCancel(){
              console.info("resetPasswordCancel");
         },
         emailSent(){
             console.info("emailSent");
         },

        checkReinitFormValidity() {
            const valid = this.$refs.formReinitPassword.checkValidity()
            this.emailForReinitState = valid && this.emailForReinit.length >= 6;
            return valid && this.emailForReinit.length >= 6;
        },
        handleSubmitReinitPassword(){
            console.info("handleSubmitReinitPassword");
            if (!this.checkReinitFormValidity()){
                return;
            }
            this.resetPasswordOk(this.emailForReinit);
        },
        reinitClicked(){
             this.handleSubmitReinitPassword();
        }


    }
};
</script>

<style lang="scss">
    @import "login-component.scss";
</style>

<template src="./login-component.html"></template>