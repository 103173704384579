<template>
    <div class="modal-email-component">
        <b-modal v-model="showed"
            id="LoginPass-modal" 
            :centered="true" 
            footer-class="centered-footer"
            :hide-header="false"
            ok-title="Valider"
            cancel-title="Annuler"
            cancel-variant="outline-secondary"
            :title="modalMessage"
            @ok="userValidation"
            @cancel="userCancel">
            <form ref="formLoginPass" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    :state="emailState"
                    label="Email"
                    label-for="email-input"
                    invalid-feedback="veuillez entrer un email valide !">
                    <b-form-input
                    id="email-input"
                    v-model="email"
                    :state="emailState"
                    type="email"
                    required
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ModalEmailComponent",
    components: {
    },
    props: ['modalMessage','okFunc','cancelFunc'],
    data() {
      return {
          showed:false,
        email: '',
        emailState: null,
      }
    },
    methods: {
        showModal() {
            this.email = '';
            this.emailState = null;
            this.showed = true;
        },
        userValidation(bvModalEvt) {
            // Trigger submit handler
            this.handleSubmit(bvModalEvt);
            this.showed = false;
        },
        userCancel() {
            console.log('cancelled modal action')
            this.cancelFunc();
            this.showed = false;
        },
        checkFormValidity() {
            const valid = this.$refs.formLoginPass.checkValidity()
            this.emailState = valid && this.email.length >= 6;
            return valid && this.email.length >= 6;
        },
        handleSubmit(bvModalEvt) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                 // Prevent modal from closing
                bvModalEvt.preventDefault();
                return;
            }
            this.okFunc(this.email);

            // Hide the modal manually
           // this.$nextTick(() => {
             //   this.$bvModal.hide('modal-prevent-closing')
          //  })
        }
    }
};
</script>

<style lang="scss">
    @import "modal-email-component.scss";
</style>